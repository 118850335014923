.reserve__buttons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    gap: 15px;

    padding: 50px;
    
}

.reserve__buttons .btn-reserve {
    width: 140px;
    height: 60px;

    font-family: 'Markazi Text', serif;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 650;

    border-radius: 10px;

    border: 1px solid #495E57;
    color: #495E57;
}

.reserve__buttons .btn-reserve:hover {
    background-color: #F4CE14;
    cursor: pointer;
}

.reserve__buttons .btn-reserve:disabled {
    background-color: gray;
    cursor: not-allowed;
}
