/* @import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Markazi+Text:wght@400;500;600;700&display=swap'); */


/* 
    font-family: 'Karla', sans-serif;
*/

/* 
        font-family: 'Markazi Text', serif;
*/


.app__footer {
    background-color: #EDEFEE;
}


.app__footer-section {
    /* display: grid;
    grid-template-columns: repeat(4, auto); */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    color: #495E57;
}

.app__about-image-box {
    width: 290px;
    height: 380px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;

    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.app__footer-items {
    margin-top: 50px;
}

.app__footer-item-title {
    font-family: 'Markazi Text', serif;
    font-size: 34px;
    font-weight: 850;
}

.app__footer-item-names {
    font-family: 'Karla', sans-serif;
    font-size: 20px;

    list-style-type: none; 
}

.app__footer-item-names > li {
    margin-top: 7px;
}
.app__footer-item-names > li > a {
    text-decoration: none;
    text-transform: uppercase;
    color: #333333;
}

@media screen and (max-width: 580px) {
    .app__footer-section {
        flex-direction: column;
        gap: 0;
    }
    .app__footer-items {
        margin-top: 10;
    }
    .app__footer-item-title{
        font-size: 28px;
    }
    .app__about-image-box {
        display: none;
    }

    .app__footer-item-names > li > a {
        font-size: 18px;
    }
}