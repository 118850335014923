.reservation__date-selector {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;

    justify-content: center;
    padding: 50px;

    font-family: 'Markazi Text', serif;
    font-size: 48px;

    background-color: #EDEFEE;
}

.reservation__date-selector-date-time {
    /* border: 1px solid black; */
    display: flex;
    justify-content: space-between;
}

.title {
    color: #495E57;
}
.date-time {
    /* border: 1px solid red; */
    display: flex;

    flex-direction: column;
    justify-content: center;

    text-align: center;

    padding: 5px;
}

.date, .time {
    background-color: #495E57;
    color: #EDEFEE;
    border: 1px solid #EDEFEE;
    border-radius: 7px;
    padding: 7px;
}

.open-closed {
    /* border: 1px solid red; */
    display: flex;  
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    text-transform: uppercase;

    font-size: 48px;
}

.closed {
    color: red;
}

.choose-date {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 3rem;
    background-color: #f1f1f1;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
}

input[type="date"] {
    background-color: #495E57;
    color: #ffffff;
    font-size: 24px;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;

    width: 200px;
    height: 60px;
}

::-webkit-calendar-picker-indicator {
    background-color: #ffffff;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}

.ocassion-selector {
        background-color: #495E57;
        color: #ffffff;
        font-size: 24px;
        padding: 10px;
        border: none;
        outline: none;
        border-radius: 5px;
    width: 200px;
        height: 60px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='30' viewBox='0 0 24 24' width='30' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
}

@media screen and (max-width: 780px) {
    .reservation__date-selector {
        font-size: 28px;
        padding: 25px;
    }
    .choose-date {
        flex-direction: column;
    }
}