.app_about-section {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Karla', sans-serif;
    /* border: 1px solid red; */

    flex-wrap: wrap;
}

.app__about-image-holder {
    display: flex;
    position: relative;
    /* border: 5px solid rgb(0, 166, 255); */
    width: fit-content;
}

.img-box-2 {
    position: absolute;
    top: -100px;
    left: 180px;
}

.app__about-image-box {
    width: 273px;
    height: 338px;
    /* border: 3px solid red; */

    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;

    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.app_about-description-box {
    width: 40%;
    /* border: 1px solid rebeccapurple; */
    display: inline-block;
}
.app__about-description {
    margin-top: 50px;
    width: 80%;
    text-align: justify;
    font-size: 18px;
    color: #495E57;
    display: inline-block;
    /* border: 1px solid rebeccapurple; */
}
.app__about-title {
    font-size: 64px;
    color: #F4CE14;
    /* border: 1px solid rebeccapurple; */
    display: inline-block;

}
.app__about-subtitle {
    margin-top: 7px;
    font-size: 40px;
    color: #495E57;
    display: inline-block;
    /* border: 1px solid rebeccapurple; */
}

@media screen and (max-width: 1020px) {
    .app__about-image-box {
        width: 203px;
        height: 278px;
        display: block;
    }

    .app_about-section {
        flex-direction: column;
    }

    .app_about-description-box {
        width: 80%;
        text-align: center;

    }
        

    .img-box-2 {
        position: absolute;
        top: 100px;
        left: 180px;
    }
    .app__about-image-holder{
        margin-top: 50px;
        justify-content: center;
    }
}

@media screen and (max-width: 700px){
    .app__about-image-holder{
        width: 80%;
    }
    .img-box-2 {
        display: none;
    }
}